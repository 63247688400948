import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./App.css";

import ResponsiveAppBar from "./ResponsiveAppbar/ResponsiveAppBar";

// images
import logo from "./images/08/Dec-Tec-DIY-Logo-300x107.jpg";
import lowesLogo from "./images/09/LowesLogo_RGB.png";
import homedepotLogo from "./images/09/THD_logo-150x150.jpg";
import USFlag from "./images/08/USflag.png";
import CDNFlag from "./images/08/CDNflag.jpg";
import Shopify from "./images/08/Shopify.jpg";

// product images
import promenadeTan from "./images/08/Dec-Tec-DIY-Promenade-Tan-Membrane-image-Tile-300x300.jpg";
import promenadeGray from "./images/08/Dec-Tec-DIY-Promenade-Grey-Membrane-image-Tile-300x300.jpg";

import camouflageTan from "./images/08/Dec-Tec-DIY-Camouflage-Tan-Membrane-image-Tile-300x300.jpg";
import camouflageGray from "./images/08/Dec-Tec-DIY-Camouflage-Grey-Membrane-image-Tile-300x300.jpg";

import ridgelineTan from "./images/08/Dec-Tec-DIY-Ridgeline-Tan-Membrane-image-Tile-300x300.jpg";
import ridgelineGray from "./images/08/Dec-Tec-DIY-Ridgeline-Grey-Membrane-image-Tile-300x300.jpg";

import promenadePattern from "./images/09/Promenade-Membrane-Pattern-Inspiration-Piece-e1695158642158.jpeg";
import camouflagePattern from "./images/09/Camo-Membrane-Pattern-Inspiration-Piece-e1695158648657.jpeg";
import ridgelinePattern from "./images/09/Ridgeline-Membrane-Pattern-Inspiration-Piece-e1695158631639.jpeg";
//pdf
import warrantyPdf from "./images/08/Dec-Tec-DIY-Warranty.pdf";
import installationPdf from "./images/08/AM-004-Dec-Tec-DIY-Installation-Guide.pdf";
import maintenancePdf from "./images/08/AM002-Dec-Tec-DIY-Care-Maintenance-Guide-v.01.pdf";

function App() {
  const Benefits = React.useRef(null);
  const Products = React.useRef(null);
  const Documentation = React.useRef(null);

  const scrollToSection = (e) => {
    window.scrollTo({
      top: e.current.offsetTop,
      behaviour: "smooth",
    });
  };


  const handleButtonClick = React.useCallback((url) => {
    window.open(url);
  }, []);

  return (
    <div className="App">
      <header className="header-content">
        <ResponsiveAppBar
          Benefits={Benefits}
          Products={Products}
          Documentation={Documentation}
          scrollToSection={scrollToSection}
        />

        <div className="header-body">
          <div
            style={{
              maxWidth: "80%",
              minWidth: "300px",
              margin: "auto",
            }}
          >
            <div className="title">
              <h1>Redefining Your Outdoor Surfaces</h1>
            </div>

            <p
              style={{
                fontWeight: "500",
                marginTop: "70px",
                maxWidth: "80%",
                margin: "auto",
              }}
            >
              Dec-Tec DIY is the perfect solution to refinishing your deck and
              outdoor surfaces without the mess or hassle of primers, adhesives,
              paints or coatings.
            </p>
            <p>
              Once the surface is ready, all you need to do is peel and stick!
            </p>
          </div>

          <div
            className="store-icons-wrapper"
            style={{
              paddingTop: "150px",

              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "30px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <h3>Click here to Purchase Dec-Tec DIY at:</h3>
            </div>

            {/* LOWES */}
            <a
              href="https://www.lowes.com/pl/Dec-tec--Deck-tiles-Decking-Building-supplies/959458694?refinement=1498228673"
              target="_blank"
            >
              <div className="clickable-icons">
                <img
                  title="Click here to check out our offers!"
                  width="120"
                  height="70"
                  src={lowesLogo}
                  style={{ padding: "15px 0px 15px 0px" }}
                  alt="lowes-logo"
                />
              </div>
              <img
                  width="45"
                  height="25"
                  src={USFlag}
                  alt="US Flag"
                />
            </a>

            {/* HOME-DEPOT */}
            <a
              href="https://www.homedepot.com/b/Lumber-Composites-Decking-Deck-Tiles/Dec-Tec/N-5yc1vZc224Zoy3"
              target="_blank"
            >
              <div className="clickable-icons">
                <img
                  title="Click here to check out our offers!"
                  width="105"
                  height="100"
                  src={homedepotLogo}
                  style={{ padding: "0px 7px 0px 7px" }}
                  alt="homedepot-logo"
                />
              </div>
              <img
                  width="45"
                  height="25"
                  src={USFlag}
                  alt="US Flag"
                />
            </a>

            {/* SHOPIFY */}
            <a
              href="https://dec-tec-diy.myshopify.com/collections/diy-membranes"
              target="_blank"
            >
              <div className="clickable-icons">
                <img
                  title="Click here to check out our offers!"
                  width="105"
                  height="100"
                  src={Shopify}
                  style={{ padding: "0px 7px 0px 7px" }}
                  alt="homedepot-logo"
                />
              </div>
              <img
                  width="45"
                  height="25"
                  src={CDNFlag}
                  alt="Canadian Flag"
                />
            </a>
          </div>
        </div>
      </header>

      {/* SVG - Wave section divider */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 45 1440 150">
        <path
          fill="#D8D8D8"
          d="M0,192L60,176C120,160,240,128,360,112C480,96,600,96,720,112C840,128,960,160,1080,154.7C1200,149,1320,107,1380,85.3L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>

      <div className="description-section">
        <div style={{ maxWidth: "70%", margin: "auto" }}>
          <h1 style={{ marginTop: "-10px", fontSize: "2.7vh" }} ref={Benefits}>
            Dec-Tec DIY
          </h1>

          <p>
            Dec-Tec DIY is a product made for the outdoors. It was specifically
            created for those inclined to tackle the Do-It-Yourself projects and
            designed to withstand extreme temperature swings, intense BBQ
            parties, and Sunday beers. It is anti-slip, water-resistant,
            fire-resistant and all-weather ready making it the perfect
            application for decks, walkways, garages, patios, balconies, and
            various other outdoor surfaces. Dec-Tec DIY is the best choice for
            quick-install, industrial strength, outdoor solutions.
          </p>

          <h2 style={{ marginTop: "50px" }}>
            Dec-Tec DIY Feature and Benefits: unique to all DIY membranes:
            <hr className="divider-dec-tec-color" />
          </h2>
          <div className="resp-container-horizontal">
            <ul>
              <li>
                Born from single ply roofing and decking protection membranes –
                Dec-Tec DIY is a product made for the outdoors.
              </li>
              <li>
                Self-adhered application – makes it easy for the
                Do-It-Yourselfer to simply peel and stick the membrane into
                place.
              </li>
              <li>
                Rolled up and packed within a box – protection during transport,
                easily unrolled, pre-fit, and positioned for installation. No
                messing with brushes, pails of stain, paint, or coatings.
              </li>
              <li>
                Finished product – No messing with brushes, pails of stain,
                paint, or coatings. Simply unroll, pre-fit, peel and stick into
                place.
              </li>
              <li>
                Aesthetic appeal – upgrades your outdoor floor and enhances your
                outdoor living space, sure to garner compliments from friends
                and family.
              </li>
            </ul>

            <ul>
              <li>
                Application versatility – although mostly used in decking
                surface applications, can be used for sunrooms, docks, RV
                floors, garage floors, shed floors, walls, dream big!
              </li>
              <li>
                North American made – proudly manufactured and made in the USA.
              </li>
              <li>
                Fiberglass reinforcement – multidirectional fiberglass mat,
                means structural integrity.
              </li>
              <li>
                Spread coat manufacturing – high quality molten plastisol
                encapsulating the fiberglass reinforcement, yields a monolithic
                membrane.
              </li>
              <li>
                Thickness – 60 mils of plastisol, finished membrane is thick and
                durable.
              </li>
              <li>
                PVC membrane – Dec-Tec DIY membrane will not absorb nor allow
                water to pass through the membrane material.{" "}
              </li>
            </ul>

            <ul>
              <li>
                High-definition print – color depth and clarity of design
                eliciting a finish that will be enjoyed for years.
              </li>
              <li>
                Superior wear protection – standing up to the everyday use of
                the homeowner.
              </li>
              <li>
                UV protection – extends the beauty of your deck via color
                retention.
              </li>
              <li>
                Slip resistance – safety underfoot with an NFSI High Traction
                rating.
              </li>
              <li>
                Fire rated – product will resist flame because of the
                high-quality PVC used, fiberglass reinforcement and spread coat
                manufacturing methodology.
              </li>
              <li>
                Low maintenance – easy to clean with common household products.{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 180 1440 155">
        <path
          fill="#D8D8D8"
          d="M0,192L60,192C120,192,240,192,360,202.7C480,213,600,235,720,250.7C840,267,960,277,1080,277.3C1200,277,1320,267,1380,261.3L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
      {/* ---SVG END--- */}

      <div ref={Products} className="feature-products-section">
        <h1>Feature Products</h1>
        <div className="products resp-container-horizontal">
          <div className="product-items">
            <p>
              <b>Promenade</b> is offered in gray and tan variants, Dec-Tec DIY
              Promenade has an eye-catching geometric design, it’s performance
              and elegance instantly upgrades your outdoor flooring providing a
              premium outdoor living space feel.
            </p>

            <img
              // style={{ paddingTop: "15px", marginBottom: "-20px" }}
              src={promenadeTan}
              alt="promenade tan"
            />
            <h3 style={{ marginBottom: "35px" }}>Promenade Tan</h3>

            <img src={promenadeGray} alt="promenade gray" />
            <h3 style={{ marginTop: "20px" }}>Promenade Gray</h3>
          </div>

          <div className="product-items">
            <p>
              <b>Camouflage</b> is offered in gray and tan variants, Dec-Tec DIY
              Camouflage introduces the camo aesthetic to your outdoor floor
              which defend against the elements and blends into your living
              space.
            </p>
            <img
              // style={{ paddingTop: "15px", marginBottom: "-20px" }}
              src={camouflageTan}
              alt="camouflage tan"
            />
            <h3 style={{ marginBottom: "35px" }}>Camouflage Tan</h3>
            <img src={camouflageGray} alt="camouflage gray" />
            <h3 style={{ marginTop: "20px" }}>Camouflage Gray</h3>
          </div>

          <div className="product-items">
            <p>
              <b>Ridgeline </b> is offered in gray and tan variants, Dec-Tec DIY
              Ridgeline offers a timeless wood grain design, no slivers here, it
              is tough, clean, and low maintenance, providing solid peace of
              mind.
            </p>

            <img
              // style={{ paddingTop: "15px", marginBottom: "-20px" }}
              src={ridgelineTan}
              alt="ridgeline tan"
            />
            <h3 style={{ marginBottom: "35px" }}>Ridgeline Tan</h3>
            <img src={ridgelineGray} alt="ridgeline gray" />
            <h3 style={{ marginTop: "20px" }}>Ridgeline Gray</h3>
          </div>
        </div>

        <div className="pattern">
          <img
            width={380}
            height={380}
            src={promenadePattern}
            alt="promenade pattern"
          />
          <img
            width={380}
            height={380}
            src={camouflagePattern}
            alt="camouflage pattern"
          />
          <img
            width={380}
            height={380}
            src={ridgelinePattern}
            alt="ridgeline pattern"
          />
        </div>
      </div>
      <div ref={Documentation} className="customer-documentation">
        <h1 style={{ textAlign: "center" }}>Customer Documentation</h1>
        <div className="customer-documentation-items resp-container-horizontal">
          <div>
            <h2>
              OUR DEC-TEC DIY <b>WARRANTY</b>
            </h2>
            <hr className="divider-dec-tec-color" />
            <p>
              Our warranties are close to our hearts because we know that a
              product’s warranty is a nod to its integrity. We designed our
              warranties to keep our customer’s mind at ease, but also to show
              that we stand by our product.
            </p>

            <Button
              className="download-button"
              size={"large"}
              onClick={() => handleButtonClick(warrantyPdf)}
            >
              Download
            </Button>
          </div>
          <div>
            <h2>
              OUR DEC-TEC DIY <b>INSTALLATION GUIDE</b>
            </h2>
            <hr className="divider-dec-tec-color" />
            <p>
              Our installation guide, your go-to resource for a hassle-free
              journey into our products and services. We understand that
              starting something new can be daunting, which is why we’ve crafted
              this comprehensive installation document just for you. Whether
              you’re a seasoned pro or a first-time user, our step-by-step
              instructions and expert tips will ensure that you hit the ground
              running. Get ready to unlock the full potential of our offerings
              with confidence, thanks to our user-friendly installation guide.
            </p>

            <Button
              className="download-button"
              size={"large"}
              onClick={() => handleButtonClick(installationPdf)}
            >
              Download
            </Button>
          </div>
          <div>
            <h2>
              OUR DEC-TEC DIY <b>MAINTANENCE GUIDE</b>
            </h2>
            <hr className="divider-dec-tec-color" />
            <p>
              Discover the key to prolonging the life and performance of your
              purchase with our comprehensive maintenance document. We’ve
              crafted this essential resource to make product care effortless
              for you. Whether you’re experienced or new to our products, our
              instructions and expert advice will empower you to keep your
              investment in top shape. Unleash the full longevity and potential
              of your purchase with confidence, thanks to our user-friendly
              maintenance guide.
            </p>

            <Button
              className="download-button"
              size={"large"}
              onClick={() => handleButtonClick(maintenancePdf)}
            >
              Download
            </Button>
          </div>
        </div>
      </div>

      <div className="footer resp-container-horizontal">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            margin: "30px",
          }}
        >
          <img
            src={logo}
            width="280"
            height="100"
            className="app-logo"
            alt="app-logo"
            style={{ marginBottom: "-7px", margin: "auto" }}
          />
          <p style={{ color: "white" }}>© 2023 Skyline Building Systems Inc.</p>
        </div>
        <div
          className="resp-container-horizontal "
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "60px",
            color: "White",
            justifyContent: "space-around",
          }}
        >
          <div>
            <b>Email: </b>
            <a className="email" href="mailto:Sales@dec-tec.com">
              Sales@dec-tec.com
            </a>
          </div>
          <div>
            <b>Toll Free: </b> 1-866-461-3914
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
